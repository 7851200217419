const Clipboard = require('Vendor/clipboard');

module.exports = ClipboardSetup;

function ClipboardSetup() {
	if(!Clipboard.isSupported()) { return; }
		
	var copied = new Clipboard('.js-copy');

	copied.on('success', function(e) {
		console.log("Copied: " + e.text);

		e.clearSelection();
		var successTarget = $(e.trigger).data('clipboardSuccess');

		if(successTarget){
			$(successTarget).collapse('show');

			if($(e.trigger).data('clipboardHide')){
				setTimeout(function() {
					$(successTarget).collapse("hide");
				}, 3000);
			}
		}
	});
}