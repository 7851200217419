'use strict';

import Fetcher  from 'Scripts/common/fetcher';
import Form     from 'Scripts/common/form';
import Spectrum from "Vendor/spectrum-colorpicker";

export default class CustomBranding {
	constructor() {
		this.formEl = $("#js-branding-form");

		this.form = new Form({
			form: this.formEl,
			callback: this.submit.bind(this),
			willRedirect: true,
		});

		this.ui = {
			inputs: this.formEl.find("input"),
			modal: $("#modal-branding-info"),
		};

		this.preview = new Fetcher({
			data:     [],
			element:  "#js-preview-fetcher",
		});

		this.bindEvents();

		this.initSpectrum();
	}

	initSpectrum() {
		$('.js-colour-picker').each((i,picker) => {
			let element       = $(picker);
			let initialColour = element.data('initialColour');
			
			element.val(initialColour);

			element.spectrum({
				color: initialColour,
				showInput: true,
				preferredFormat: "hex",
				showPalette: true,
				showSelectionPalette: true,
				palette: [ ],
				localStorageKey: "spectrum.gayl"
			}).on('dragstop.spectrum', (e, colour) => {
				// Manually set the colour and fire the change event to update the button
				element.spectrum('set', colour.toHexString()).trigger('change');
			});
		});
	}

	bindEvents() {
		$(".js-change-style").off().on('click', this.onChange.bind(this));

		this.ui.inputs.off().on('input change', this.getPreview.bind(this));
		this.ui.modal.off().on('show.bs.modal', this.onShowModal.bind(this));
	}

	onChange(e) {
		console.log("CustomBranding.onChange", e);
		let element = $(e.currentTarget);
		let target = $(element.data('target'));
		let value = element.data('value');

		if(target.hasClass('js-colour-picker')) {
			target.spectrum("set", value);
		}

		target.val(value).trigger('change');
	}

	getPreview(e) {
		let formData = new FormData(this.formEl[0]);

		var data = {};
		formData.forEach((value, key) => data[key] = value);

		this.timer;
		clearTimeout(this.timer);

		// Wait for 200ms of non-typing
		this.timer = setTimeout(() => {
			console.log("CustomBranding.getPreview", e);
			this.preview.options.data = data;
			this.preview.fetch();
			// this.updateColourPalette();
		}, 300);
	}

	onShowModal(e) {
		let button = $(e.relatedTarget);
		this.ui.modal.find('h6').html(button.data('heading'));
		this.ui.modal.find('p').html(button.data('content'));
	}

	submit() {
		history.scrollRestoration = 'manual';
		location.reload();
	}
}