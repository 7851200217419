// JS for GAYL Corporate

// Import SASS/SCSS styles
import 'Sass/corporate.scss';

// Import JavaScript modules
import 'Vendor/parsleyjs';

// Import common modules
const Animate                 = require("Scripts/common/animate");
const CharityChooser          = require('Scripts/common/charity-chooser');
const ClipboardSetup          = require('Scripts/common/clipboard');
const DropdownMenu            = require('Scripts/common/nav-dropdown');
const FormUtils               = require('Scripts/common/form-utils');
const Global                  = require('Scripts/common/global');
const Fees                    = require('Scripts/common/fees');
const Fetcher                 = require('Scripts/common/fetcher');
const Loader                  = require('Scripts/common/loader');
const LoginSignup             = require('Scripts/common/login-signup');
const MobileMenu              = require('Scripts/common/nav-mobile');
const OwlCarousel             = require('Scripts/common/owl-carousel');
const Share                   = require('Scripts/common/share');
const ShowHide                = require('Scripts/common/showhide');
const SupportTicket           = require('Scripts/common/support-ticket');

// Corporate Pages
const Admins     			  = require('Scripts/corporate/admins');
const CampaignClose           = require('Scripts/corporate/campaign/campaign-close');
const CampaignForm            = require('Scripts/corporate/campaign/campaign-form');
const CharityList             = require('Scripts/corporate/charity-list');
const CardDetails             = require('Scripts/corporate/card-details');
const CorporateDetails        = require('Scripts/corporate/corporate-details');
const CustomBranding          = require('Scripts/corporate/custom-branding');
const CorporateImages         = require('Scripts/corporate/corporate-images');
const CreateCorporate         = require('Scripts/corporate/create-corporate');
const MatchedDonationModal    = require('Scripts/corporate/campaign/matched-donation-modal');
const SubscriptionPaymentForm = require('Scripts/corporate/subscription-payment-form');
const PersonalDetails         = require('Scripts/corporate/personal-details')
const TeamsForm               = require('Scripts/corporate/teams-form');
const VatInfoForm 			  = require('Scripts/corporate/vat-info-form');
const MatchedDonations   	  = require('Scripts/corporate/matched-donations');

// Borrowed from GAYL Donate
const CharitySplits           = require('Scripts/donate/charity-splits');
const OfflineAmountsModal     = require('Scripts/donate/offline-amounts-modal');
const PageUpdateModal         = require('Scripts/donate/page-update-modal');

// Borrowed from GAYL Admin
const ResetPassword  		  = require('Scripts/admin/reset-password');
const SetPassword  			  = require('Scripts/admin/set-password');

// Define groups of modules - the group name is set in your template ($jsModuleGroup). 
// This is just a nicer way to load JS on specific pages without looking at the path/params
// To initiate a group, use the #module macro. E.g. #module("my-group") would add 'my-group' from the groups below.
const moduleGroups = {

	// Available on every page
	'global': [
		Global, 
		ClipboardSetup,
		DropdownMenu, 
		Fetcher, 
		MobileMenu, 
		OwlCarousel, 
		ShowHide
	],

	// Available on specific pages
	'admins':          	 		[Admins],
	'animate':           		[Animate],
	'auth':              		[LoginSignup],
	'campaign-form':     		[CampaignForm],
	'campaign-tools':    		[CampaignClose, OfflineAmountsModal, MatchedDonationModal, PageUpdateModal, Share],
	'card-details':      		[CardDetails],
	'charity-chooser':   		[CharityChooser],
	'charity-list':     		[CharityList],
	'charity-splits':    		[CharitySplits],
	'corporate-details': 		[CorporateDetails],
	'corporate-images':  		[CorporateImages],
	'create-corporate':  		[CreateCorporate],
	'custom-branding':   		[CustomBranding],
	'fees':              		[Fees],
	'form-utils':        		[FormUtils],
	'matched-donations': 	    [MatchedDonations],
	'personal-details':  		[PersonalDetails],
	'reset-password':    		[ResetPassword],
	'set-password':      		[SetPassword],
	'subscription-pay':  		[SubscriptionPaymentForm],
	'support-ticket':           [SupportTicket],
	'teams-form':        		[TeamsForm],
	'vat-info-form': 	 		[VatInfoForm],

};

Loader.load(moduleGroups);
