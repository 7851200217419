// Matched donations modal and its lists / form
// @author Matthew Norris

import Fetcher from "Scripts/common/fetcher";
import Form from "Scripts/common/form";

export default class MatchedDonationsModal {
	constructor() {
		this.modal = $("#js-modal-matched-donations");
		$(".js-matched-donations-modal-trigger").on("click", this.showModal.bind(this));
	}

	showModal(event) {
		this.modal.modal("show");
		this.pageId = $(event.target).data("id");
		this.fetchList(this.pageId);
	}

	fetchList() {
		$("#js-matched-donations-list-fetcher").show();
		$("#js-matched-donations-form-fetcher").hide();
		this.listFetcher = new Fetcher({
			element:  "#js-matched-donations-list-fetcher",
			data: { "pageId": this.pageId },
			callback: this.bindList.bind(this),
		});
		this.listFetcher.fetch();
	}

	bindList() {
		$(".js-add-new-rule").click(this.fetchForm.bind(this))
		$(".js-edit-rule").click(this.fetchForm.bind(this))
	}

	fetchForm() {

		// check here whether to include the ruleId
		var data = { "pageId": this.pageId };
		var ruleId = $(event.target).data("id");
		if (ruleId) {
			data["ruleId"] = ruleId;
		}

		$("#js-matched-donations-form-fetcher").show();
		$("#js-matched-donations-list-fetcher").hide();
		this.formFetcher = new Fetcher({
			element: "#js-matched-donations-form-fetcher",
			data: data,
			callback: this.bindForm.bind(this),
		});
		this.formFetcher.fetch();
	}

	bindForm() {
		new Form({
			callback: this.postForm.bind(this),
			form:     $("#js-matched-donation-form"),
		});
		$(".js-form-cancel").click(this.fetchList.bind(this))
	}

	postForm() {
		this.fetchList();
	}
}