'use strict';

import Form            from 'Scripts/common/form';
import ImageUploadCrop from 'Scripts/common/image-upload-crop';
import TextAreaExpand  from 'Scripts/common/text-area-expand';

export default class TeamsForm {
	constructor() {
		this.form = new Form({
			form: $("#js-teams-form"),
			addToForm: this.addToForm.bind(this),
			callback: this.submit.bind(this),
			willRedirect: true,
		});

		this.logo = new ImageUploadCrop({
			width:     200,
			height:    150,
			restrict:  false,
			container: $('.js-logo-container'),
			modal:     $("#js-modal-image-crop-logo"),
		});

		// This makes <textarea> inputs expand as you type
		new TextAreaExpand();

		this.bindEvents();
	}

	bindEvents() {
		//
	}

	addToForm(formData) {
		console.log("CaseStudyForm.addToForm()")
		return this.logo.addImagesToFormData(formData, "logo");
	}

	submit() {
		window.location.href = "/manage/teams";
	}
}