import Form      from 'Scripts/common/form';
import Loqate    from 'Scripts/donate-form/loqate.js';
import TomSelect from 'Scripts/common/tom-select';

export default class VatInfoForm {
	constructor() {
		this.form = new Form({
			form: $("#js-vat-info-form"),
			callback: this.onFormSubmitSuccess.bind(this),
			willRedirect: true,
		});

		// Setup country dropdown
		const tsCountry = new TomSelect("#address.country", {
			disableAutocomplete: true,
		});

		// Set up Loqate
		new Loqate({ input: $("#loqate"), countryInput: tsCountry, enterManuallyBtn: $("#js-manual-address") });
	}

	onFormSubmitSuccess(resp) {
		window.location.href = resp.payload.redirectUrl;
	}
}