/**
 * These are just thin wrappers around the 'fetch' API which removes some boilerplate.
 * 
 * Trying to strike a balance between easy-to-use and being configurable. If your
 * use-case is not covered here, just write a new method, don't stick more parameters
 * into these methods if you can avoid it.
 *
 * Because each method returns a promise, you can handle errors in these functions by attaching a 'catch' handler, e.g.
 *
 *  httpUtils.postJson('/foo', {foo: 'bar})
 *  		 .then(json => { .. do something with json .. })
 *  		 .catch(err => {
 *  		 	.. something went wrong, inspect the error to see what ..
 *  		 })
 *
 * If you need to handle errors at specific points (e.g. you want to handle the server not
 * returning valid JSON, then write a new method that accepts an error handler, or something)
 * 
 * 
 */

/**
 * POST a JSON payload, expects a JSON response. If you are POST'ing JSON
 * and returning HTML, write another method, this should cover the most common
 * use-cases.
 * 
 * Notes:
 * - 'data' must be JSON.stringify'able so make sure it doesn't contain
 * things which can't be serialised or you may be surprised by what data
 * is sent off. See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#description
 */
export function postJson(url, data) {
	return fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data || {})
	}).then(resp => {
		return resp.json();
	});
}

/**
 * Post a formData object, expects a JSON response.
 * formData is a FormData object: https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects
 */
export function postForm(url, formData) {
	return fetch(url, {
		method: 'POST',
		body: formData
	}).then(resp => {
		return resp.json();
	});
}