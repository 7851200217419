'use strict';

import Form                   from 'Scripts/common/form';
import TextAreaExpand         from 'Scripts/common/text-area-expand';
import TomSelect              from 'Scripts/common/tom-select';
import UriAvailabilityChecker from 'Scripts/donate/uri-availability-checker';

export default class CorporateDetails {
	constructor() {
		this.form = new Form({
			form: $("#js-corporate-details-form"),
			callback: this.submit.bind(this),
			willRedirect: true,
		});

		this.ui = {
			description: $('#description'),
		};

		// URI checker
		new UriAvailabilityChecker('.js-uri-lookup', '/manage/details/uri-availability', 3); // minLength should match CorporateDetailsForm.java

		// This makes <textarea> inputs expand as you type
		new TextAreaExpand();

		// Setup country dropdown
		new TomSelect("#country");

		this.bindEvents();
	}

	bindEvents() {
		this.ui.description.on('input', this.updateCharCount.bind(this)).trigger('input');
	}

	submit() {
		history.scrollRestoration = 'manual';
		location.reload();
	}

	updateCharCount(e) {
		console.log("CorporateDetails.updateCharCount()", e.target.value.length);

		let count = e.target.value.length;
		let countText = $(".js-desc-length");
		let max = parseInt(countText.data('max'));
		let perc = count / max;

		countText.html(count);
		countText.toggleClass("text-orange", perc > 0.75 && perc < 1);
		countText.toggleClass("text-red", perc >= 1);
	}
}