// JS to handle verification process for repeat donations. User is shown 3D secure screen, where they can
// confirm the transaction with their bank.
// @author Matthew Norris

import StripeUtils from 'Scripts/donate-form/stripe-utils.js';
import Fetcher from "Scripts/common/fetcher";

export default class VerifyMatchedDonation {
	
	constructor() {
		console.log("VerifyMatchedDonation.constructor() called");

		this.list = new Fetcher({
			element:  "#js-matched-donations-fetcher",
			callback: this.bindListButtons.bind(this),
		});
		// this.list.fetch()

		this.stripeUtils = new StripeUtils({
			isRepeat: true,
			confirmEndpoint: "/finance/matched-donations/confirm",
			onSuccess: function() {
				$("#js-auth-fail").hide();
				$("#js-auth-success").show();
				this.list.fetch();
				
			}.bind(this),
			onFail: function() {
				$("#js-auth-success").hide();
				$("#js-auth-fail").show();
				this.list.fetch();

			}.bind(this)
		});
		$("#js-authenticate").click(this.verify.bind(this));
	}

	bindListButtons() {
		console.log("bindListButtons() called");
		$(".js-authorise").click(this.verify.bind(this));
	}


	verify(event) {
		console.log("verify() called");
		var button = $(event.target);
		button.addClass("disabled").html("Please wait...");

		console.log("data: "+button.data("donationId"));

		return $.ajax({
			url: '/finance/matched-donations/verify',
			type: 'POST',
			data: { "donationId": button.data("donationId") }
		}).then(this.stripeUtils.verify)
	}
}
