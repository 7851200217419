// Corporate admin management page
// @author Matthew Norris

import Fetcher       from "Scripts/common/fetcher";
import Form          from "Scripts/common/form";
import LoadingButton from "Scripts/common/loading-button";

export default class Admins {
	constructor() {
		this.newAdminModal = $("#js-new-admin-modal");
		this.adminList = new Fetcher({
			element: "#js-admins-fetcher",
			callback: this.bindButtons.bind(this),
		});

		$("#js-admin-new").on('click', this.newAdmin.bind(this));

		this.deleteModal = $("#contacts-delete-modal");
	}

	bindButtons() {
		$(".js-contacts-resend-link").on('click', this.resendActivationLink.bind(this));
		$(".js-admin-make-primary").on('click', this.makePrimary.bind(this));
		$(".js-admin-delete").on('click', this.showDeleteModal.bind(this));
	}

	// Resend an admin activation link
	resendActivationLink(e) {
		var button = $(e.currentTarget);
		var adminId = button.data("admin-id");

		$(".js-contacts-resend-link[data-admin-id=\""+adminId+"\"]").hide();
		$(".js-contacts-resend-link-message[data-admin-id=\""+adminId+"\"]").text("Resending...");
		$(".js-contacts-resend-link-message[data-admin-id=\""+adminId+"\"]").show();
		$.ajax(button.data('target'), {
			method: "POST", 
			data: {"adminId": adminId}
		}).done((response) => {
			$(".js-contacts-resend-link-message[data-admin-id=\""+adminId+"\"]").text("Activation link re-sent.")
		});
	}

	newAdmin(e) {
		new Fetcher({
			callback: this.setupNewAdminForm.bind(this),
			data: $(e.currentTarget).data(), // Includes the ID (if editing)
			element: "#js-new-admin-modal-fetcher",
			modal: this.newAdminModal,
		});
	}

	setupNewAdminForm() {
		new Form({
			callback: this.postNewAdminForm.bind(this),
			form: $("#js-admin-new-form"),
		});
	}

	postNewAdminForm() {
		this.newAdminModal.modal("hide");
		this.adminList.reset();
	}

	makePrimary(e) {
		var button = $(e.currentTarget);
		$.ajax(button.data('target'), {
			method: "POST", 
			data: {"adminId": button.data("admin-id")}
		}).done((response) => {
			this.adminList.reset();
		});
	}

	showDeleteModal(e) {
		console.log("Admins.showDeleteModal()");

		var button = $(e.currentTarget);
		var modalBody = this.deleteModal.find(".modal-body");
		var modalSpinner = this.deleteModal.find(".modal-body-spinner");

		modalBody.empty();
		modalSpinner.show();
		this.deleteModal.modal('show');

		$.ajax(button.data('target'), {
			method: "GET", 
			data: {"adminId": button.data("admin-id")}
		}).done(response => {
			modalSpinner.hide();
			modalBody.html(response);

			this.deleteBtn = new LoadingButton($("#js-delete-confirm"));
			this.deleteBtn.el().on('click', this.delete.bind(this));
		});
	}

	delete(e) {
		console.log("Admins.delete()")
		var button = $(e.currentTarget);

		this.deleteBtn.disable();

		$.ajax(button.data('target'), {
			method: "POST", 
			data: {"adminId": button.data("admin-id")}
		}).done((response) => {

			console.log(response);
			
			this.adminList.reset();
			this.deleteBtn.enable();
			this.deleteModal.modal('hide');
		});
	}
}