/**
 * The Stripe code was based on this sample in their docs: https://stripe.com/docs/payments/accept-a-payment-synchronously?platform=web#web-collect-card-details
 *
 * Some things worth noting:
 * - I've used multiple elements for card number/expiry/cvc instead of an all-in-one input
 * they offer because it's more flexible on smaller screens.
 *
 * Some possible improvements:
 * - Do our validation before creating a Stripe payment method ID, would reduce the # of redundant payment methods getting created.
 * 
 * todo:
 * - Payments with a wallet (Apple/Google Pay), I left this off for now as it's unlikely they'll be paying for something like this on their phone.
 */

import AnalyticsEvent from 'Scripts/common/analytics-event';
import AjaxFormErrorHandler from "Scripts/common/ajax-form-error-handler";
import LoadingButton from 'Scripts/common/loading-button';
import stripeStyles from 'Scripts/donate-form/stripe-styles';
import TomSelect from 'Scripts/common/tom-select';
import { postJson, postForm } from 'Scripts/common/http';

// Types of error a Stripe Elements input can have.
const StripeInputError = {
	VALIDATION: 'validation_error'
};

// Types of payment errors from our server
const PaymentErrorTypes = {
	ALREADY_HAS_PLAN: 'ALREADY_HAS_PLAN',
	STRIPE_EXCEPTION: 'STRIPE_EXCEPTION',
	REQUIRES_ADDITIONAL_AUTH: 'REQUIRES_ADDITIONAL_AUTH'
};

// Fatal errors that are unrecoverable
const FatalPaymentErrorTypes = {
	FAILED_ADDITIONAL_AUTH: 'FAILED_ADDITIONAL_AUTH',
	GENERIC: 'GENERIC'
};

// This handler gets bound to the form whilst we're talking to Stripe.
const DO_NOTHING = e => {
	e.preventDefault();
};

export default class SubscriptionPaymentForm {
	constructor() {
		// Without Stripe SDK, nothing will work.
		assertStripeSdkLoaded();

		this.$form = $('.js-subscription-payment-form');
		this.$paymentMethodIdInput = this.$form.find('.js-payment-method-id-input');
		this.checkDiscountButton = new LoadingButton($("#js-check-discount"));
		this.$discountInput = $("#discountCode");
		this.$chargeFrequencyDropdown = this.$form.find('.js-charge-frequency-dropdown');
		this.$billingInfo = this.$form.find('.js-subscription-billing-info');
		this.formErrorHandler = new AjaxFormErrorHandler();
		this.submitButton = new LoadingButton(this.$form.find('.js-submit'));
		this.$stripeErrorContainer = this.$form.find('.js-stripe-errors-container');

		// Stripe API objects
		this.stripe = Stripe(this.extractStripePublishableKey());
		this.stripeElements = this.stripe.elements({
			fonts: stripeStyles.fonts
		});

		// Stripe Elements inputs
		this.stripeManagedInputs = this.initStripeManagedInputs();

		this.stripeErrorCodeToErrorContainerMap = this.mapStripeErrorCodeToErrorContainer();

		this.initCountryDropDown();

		this.bindEvents();
	}

	initCountryDropDown() {
		new TomSelect("#billingDetails.address.country");
	}

	// Maps Stripe error codes to an error container on the page. This isn't an exhaustive list, and will
	// probably need to be updated.
	mapStripeErrorCodeToErrorContainer() {
		const $allStripeErrorContainers = $('.js-stripe-error');
		const $stripeCvcErrorContainer = $('.js-stripe-error[data-error-field="cardCvc"]')
		const $stripeExpiryErrorContainer = $('.js-stripe-error[data-error-field="cardExpiry"]')
		const $stripeCardNumberErrorContainer = $('.js-stripe-error[data-error-field="cardNumber"]')

		return {
			'incomplete_cvc': $stripeCvcErrorContainer,
			'incomplete_expiry': $stripeExpiryErrorContainer,
			'incomplete_number': $stripeCardNumberErrorContainer,
			'invalid_expiry_year_past': $stripeExpiryErrorContainer,
			'invalid_expiry_month_past': $stripeExpiryErrorContainer,
			'invalid_number': $stripeCardNumberErrorContainer,
		};
	}

	bindEvents() {
		this.$form.on('submit', this.onFormSubmitted.bind(this));
		this.checkDiscountButton.button.on('click', this.onCheckDiscount.bind(this));
		this.$chargeFrequencyDropdown.on('change', this.onChargeFrequencyDropdownChanged.bind(this));
		this.$discountInput.on('keydown', this.onTypeDiscount.bind(this));

		// docs: https://stripe.com/docs/js/element/events
		this.stripeManagedInputs.cardNumber.on('change', this.onStripeManagedInputChange.bind(this));
		this.stripeManagedInputs.cardExpiry.on('change', this.onStripeManagedInputChange.bind(this));
		this.stripeManagedInputs.cardCvc.on('change', this.onStripeManagedInputChange.bind(this));
	}

	showStripeErrorMessage(errorCode, errorMessage) {
		const $errorContainer = this.stripeErrorCodeToErrorContainerMap[errorCode];
		// We want to know about this. We can update the map with new codes - we could also have a catch-all
		// for unmapped errors.
		if(!$errorContainer) {
			throw new Error(`Error code without corresponding error container: ${errorCode}`);
		}

		$errorContainer.html(errorMessage).show();
	}

	hideStripeErrorMessage(elementType) {
		$(`.js-stripe-error[data-error-field="${elementType}"]`).html('').hide();
	}

	onTypeDiscount(e) {
		const keycode = e.charCode || e.keyCode || 0;

		if(keycode == 13) {
			e.preventDefault();
			this.checkDiscountButton.button.trigger('click');
		}
	}

	onCheckDiscount(e) {
		let button = $(e.currentTarget);
		let discountCode = $(button.data('target')).val();
		let error = $("#error_discountCode");

		if(discountCode === "") return;

		console.log("SubscriptionPaymentForm.checkDiscountButton", discountCode);

		this.checkDiscountButton.disable();

		postJson(`/finance/subscription/pay/apply-discount?discountCode=${discountCode}`)
			.then(json => {
				if(json.success) {
					this.checkDiscountButton.success();
					window.location.reload();
					error.html('');
				} else {
					this.checkDiscountButton.enable();
					error.html(button.data('errorInvalid'));
				}
			});
	}

	onChargeFrequencyDropdownChanged(e) {
		const selectedChargeFrequency = e.currentTarget.value;

		// todo: Implement me when I've done the prices.
		this.$billingInfo.html(selectedChargeFrequency);
	}

	extractStripePublishableKey() {
		const stripePublishableKey = this.$form.data().stripePublishableKey;

		if(!stripePublishableKey) {
			throw new Error(`Couldn't find Stripe publishable key on form element. Make sure the form has an attribute 'data-stripe-publishable-key' with the Stripe publishable key as the value.`);
		}

		return stripePublishableKey;
	}

	initStripeManagedInputs() {
		// Create the inputs
		const cardNumberElement = this.createStripeElement('cardNumber');
		const cardCvcElement = this.createStripeElement('cardCvc'); 
		const cardExpiryElement = this.createStripeElement('cardExpiry');

		// Make them interactive
		cardNumberElement.mount('.js-card-number-input');
		cardCvcElement.mount('.js-card-cvc-input');
		cardExpiryElement.mount('.js-card-expiry-input');

		// Return them so they're accessible later on.
		return {
			cardNumber: cardNumberElement,
			cardCvc: cardCvcElement,
			cardExpiry: cardExpiryElement
		};
	}

	createStripeElement(type) {
		return this.stripeElements.create(type, {
			placeholder: '',
			style: stripeStyles.cardElementStyles,
			classes: {
				focus: 'focus',
				empty: 'empty',
				invalid: ''
			}
		});
	}

	onStripeManagedInputChange(event) {
		if(!event.error) {
			const elementType = event.elementType;
			this.hideStripeErrorMessage(elementType);
			return;
		}

		// If it's not a validation error, complain and do nothing.
		if(event.error.type !== StripeInputError.VALIDATION) {
			console.log(`Found undefined error in element (${event.error.type})`, event.error);
			return;
		}
			
		console.log('Found validation error in element', event.error);
		const { code, message, type } = event.error;
		this.showStripeErrorMessage(code, message);
	}

	onFormSubmitted(e) {
		e.preventDefault();
		this.handleCardPaymentAttempt();
	}

	handleCardPaymentAttempt() {
		// It's very important the form is disabled whilst a payment method is being created, otherwise
		// we may end up with duplicate payment methods when the form is submitted multiple times.
		this.disableForm();

		// Turn their card details into a payment method ID.
		this.stripe.createPaymentMethod({
			type: 'card', 
			card: this.stripeManagedInputs.cardNumber,
			billing_details: this.extractBillingMetadata()
		})
		.then(this.onPaymentMethodCreated.bind(this))
		.catch(reason => {
			console.warn("Tried to access Stripe managed input data, but it was not available.");
			// In my experience, this occurs when you try to access a managed input's data before it's ready.
			// Re-enable the form and let them try again.
			// If this occurs in some other circumstance, we can write some proper error handling.
			this.enableForm();
		});
	}

	disableForm() {
		this.$form.off('submit');
		// Bind an empty handler to the form so submissions do nothing.
		this.$form.on('submit', DO_NOTHING);
		this.submitButton.disable();
	}

	enableForm() {
		this.$form.off('submit');
		// Re-enable the previous form submission handler
		this.$form.on('submit', this.onFormSubmitted.bind(this));
		this.submitButton.enable();
	}

	// Called when we've created a payment method ID from some card details.
	onPaymentMethodCreated(result) {
		console.log('onPaymentMethodCreated');
		console.table(result);

		// If there was an error creating it, don't do anything else and let them try again.
		if(result.error) {
			this.enableForm();
			return;
		}

		// If all was OK, update a hidden input, and submit the form to our server for proper validation.
		this.setPaymentMethodIdInput(result.paymentMethod.id);

		this.submitForm();
	}

	submitForm() {
		const formData = new FormData(this.$form.get(0));
		postForm('/finance/subscription/pay', formData).then(json => {
			// Response structure: 
			// {
			// 		success: true,
			// 		redirectUrl: '....',
			// 		error: {
			// 			type: 'REQUIRES_ADDITIONAL_AUTH',
			// 			message: 'The error message'
			// 		},
			// 		validationResponse: { ... },
			// 		requiresAdditionalAuth: true,
			// 		paymentIntentClientSecret: 'asd_12345'
			// }
			console.log('Our server response: ', json);
			
			// Handle best-case scenario first - payment goes through without problems.
			if(json.success) {
				this.submitButton.success();
				this.onPaymentSuccess(json.redirectUrl);
				return;
			}
			
			// At this point, an error has occurred, it's either:
			// - A validation error due to bad input
			// - The card used requires additional authentication.
			// - The corporate already has a plan
			
			this.clearPreviousErrors();

			// Handle validation errors
			if(json.validationResponse) {
				this.formErrorHandler.handleErrors(json.validationResponse);
				this.enableForm();
				return;
			}

			// Handle server-side errors
			const { type, message } = json.error;

			switch(type) {
				case PaymentErrorTypes.STRIPE_EXCEPTION:
					this.handleStripeException(message);
					break;
				case PaymentErrorTypes.REQUIRES_ADDITIONAL_AUTH:
					this.handleRequiresAdditionalAuth(json.paymentIntentClientSecret);
					break;
				default:
					// Generic catch-all
					this.onFatalPaymentError(FatalPaymentErrorTypes.GENERIC);
			}
		}).catch(err => {
			// This will be called if there are issues contacting the website, or another exception was thrown inside this chain.
			// It's treated as unrecoverable
			this.onFatalPaymentError(FatalPaymentErrorTypes.GENERIC);
		});
	}

	// Clear any previous validation errors + Stripe errors
	clearPreviousErrors() {
		this.formErrorHandler.clear();
		this.clearStripeError();
	}

	handleStripeException(message) {
		console.log('Stripe error: ', message);

		this.showStripeError(message);

		// Clear out the payment method ID, so we can create a new one.
		this.clearPaymentMethodIdInput();
		this.enableForm();
	}

	handleRequiresAdditionalAuth(paymentIntentClientSecret) {
		// This triggers the 3D secure modal, handled by Stripe.
		this.stripe.handleCardAction(paymentIntentClientSecret).then(result => {
			const { error, paymentIntent } = result;

			// User failed authentication, this happens if they close the authentication modal
			// or fail the authentication step (didn't go through 3DS)
			if(error) {

				// We might be able to handle these, but it requires a bunch more code to:
				// a) Find the existing payment method ID that hasn't been confirmed yet
				// b) Set the form in a state where the payment method can be confirmed.
				if(error.code === 'payment_intent_authentication_failure') {
					// Not yet implemented, and probably won't be.
				}

				this.onFatalPaymentError(FatalPaymentErrorTypes.FAILED_ADDITIONAL_AUTH);
				return;
			}

			// User passed authentication, now we can try and complete the payment
			return postJson('/finance/subscription/pay/confirm', {
				payment_intent_id: paymentIntent.id
			}).then(json => {
				if(json.success) {
					this.onPaymentSuccess(json.redirectUrl);
				} else {
					// This shouldn't happen, but if it does, it's unrecoverable and they'll need to try again.
					this.onFatalPaymentError(FatalPaymentErrorTypes.GENERIC);
				}
			});
		}).catch(err => {
			// Happens when something wrong on our end when confirming. This shouldn't happen, but treat it like an unrecoverable error
			// if it does.
			this.onFatalPaymentError(FatalPaymentErrorTypes.GENERIC);
		});
	}

	// Called when payment fails with an unrecoverable error.
	onFatalPaymentError(errorType) {
		window.location.href = `/finance/subscription/pay/error?type=${errorType}`;
	}

	onPaymentSuccess(postSubscribeUrl) {
		console.log('The payment was successful, redirecting to postSubscribeUrl');
		AnalyticsEvent.heap('Subscription');
		AnalyticsEvent.gtm('SubscriptionSuccess', {subscriptionValue:this.$form.data('value')});
		window.location.href = postSubscribeUrl;
	}

	setPaymentMethodIdInput(newPaymentMethodId) {
		this.$paymentMethodIdInput.val(newPaymentMethodId);
	}

	clearPaymentMethodIdInput() {
        this.$paymentMethodIdInput.val('');
	}

	extractBillingMetadata() {
		return {
			'email': $('#billingDetails\\.email').val(),
			'name': $('#billingDetails\\.fullName').val(),
			'address': {
				'line1': $('#billingDetails\\.address\\.line1').val(),
				'city': $('#billingDetails\\.address\\.city').val(),
				'state': $('#billingDetails\\.address\\.county').val(),
				'postal_code': $('#billingDetails\\.address\\.postcode').val(),
				'country': $('#billingDetails\\.address\\.country').val()
			}
		}
	}

	clearStripeError() {
		this.$stripeErrorContainer.hide().html('');
	}

	showStripeError(errorText) {
		this.$stripeErrorContainer.html(errorText).show();
	}

}

function assertStripeSdkLoaded() {
	if(typeof Stripe === 'undefined') {
		throw new Error(`Stripe SDK not loaded. Make sure it's included on your webpage and hasn't been blocked.`);
	}

	console.log('Stripe SDK has been loaded.');
}