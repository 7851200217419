// JS for the corporate personal details form
// @author Matthew Norris

import Form from "Scripts/common/form";

export default class PersonalDetails {

	constructor() {
		this.setupForm();
	}

	setupForm() {
		new Form({
			callback: this.postForm.bind(this),
			form: $("#js-personal-details-form"),
			willRedirect: true,
		});
	}

	postForm() {
		window.location.replace("/dashboard")
	}
}