// Card details entry, for corporate matched donations
// @author Matthew Norris

'use strict';

import Fetcher from "Scripts/common/fetcher";
import Form from "Scripts/common/form";
import LoadingButton from 'Scripts/common/loading-button';
import loadStripe from 'Scripts/common/load-stripe';
import stripeStyles from 'Scripts/donate-form/stripe-styles';

export default class CardDetails {
	constructor() {
		this.setup();
	}

	// Can't do anything until Stripe is ready
	async setup() {
		// Setup Stripe's client object - owned by this class
		var apiKey = $(document.body).data('stripeApiKey');

		this.stripe = await loadStripe(apiKey);

		this.interface = new Fetcher({
			element:  "#js-card-details-fetcher",
			callback: this.postLoad.bind(this)
		});
	}

	postLoad() {
		this.ui = {
			cardDetailsForm: $("#js-card-details-form"),
			preferencesForm: $("#js-payment-preferences-form"),
			currentMethods: $("#js-current-payment-methods"),
		}

		$("#js-cancel-card-details-entry").click(this.showSummary.bind(this));
		$("#js-cancel-payment-preferences-entry").click(this.showSummary.bind(this));
		$("#js-change-payment-method").click(this.showEnterPaymentMethod.bind(this));
		$("#js-change-payment-preferences").click(this.showEnterPaymentPreferences.bind(this));

		this.setupStripeForm();
		this.setupPreferencesForm();
	}

	showSummary() {
		console.log("CardDetails.showSummary");
		this.ui.cardDetailsForm.collapse('hide');	
		this.ui.preferencesForm.collapse('hide');
		this.ui.currentMethods.collapse('show');
		this.resetScroll(this.ui.currentMethods);
	}

	showEnterPaymentMethod() {
		this.ui.currentMethods.collapse('hide');
		this.ui.preferencesForm.collapse('hide');
		this.ui.cardDetailsForm.collapse('show');
		this.resetScroll(this.ui.cardDetailsForm);
	}

	showEnterPaymentPreferences() {
		this.ui.currentMethods.collapse('hide');
		this.ui.cardDetailsForm.collapse('hide');
		this.ui.preferencesForm.collapse('show');
		this.resetScroll(this.ui.preferencesForm);
	}

	resetScroll(el) {
		console.log("getBoundingClientRect", el[0].getBoundingClientRect())
		let rect = el[0].getBoundingClientRect();
		if(rect.top < 0 || rect.bottom > $(window).height()) {
			setTimeout(() => {
				el[0].scrollIntoView({behavior:'smooth'});
			}, 300);
		}
	}

	setupStripeForm() {	
		this.ui.button = new LoadingButton(this.ui.cardDetailsForm.find(".js-submit"));		

		this.ui.cardDetailsForm.on("submit", this.stripeFormSubmit.bind(this));

		var elements = this.stripe.elements({
			fonts: stripeStyles.fonts
		});

		var elementClasses = {
			focus: 'focus',
			empty: 'empty',
			invalid: 'parsley-error',
		};

		$('.card-error').collapse('hide');

		var cardNumber = elements.create('cardNumber', {
			style: {},
			classes: elementClasses,
		});
		this.cardNumberEl = cardNumber;

		cardNumber.mount('#card-number');

		cardNumber.on('change', function(event) {
			this.toggleStripeError(event)
		}.bind(this));

		var cardExpiry = elements.create('cardExpiry', {
			style: {},
			classes: elementClasses,
		});

		cardExpiry.mount('#card-expiry');

		cardExpiry.on('change', function(event) {
			this.toggleStripeError(event)
		}.bind(this));

		var cardCvc = elements.create('cardCvc', {
			style: {},
			classes: elementClasses,
		});

		cardCvc.mount('#card-cvc');

		cardCvc.on('change', function(event) {
			this.toggleStripeError(event)
		}.bind(this));
	}


	stripeFormSubmit() {
		try {
			this.ui.button.disable();
			this.ui.cardDetailsForm.parsley().validate();
			var isValid = this.ui.cardDetailsForm.parsley().isValid();

			// Set up meta
			var meta = {
				"email": $("#billingEmail").val(),
				"name": $('#billingName').val(),
				"address": {
					"line1": $('#billingAddress').val(),
					"city": $('#billingCity').val(),
					"state": $('#billingState').val(),
					"postal_code": $('#billingZip').val(),
					"country": $('#billingCountry').val()
				}
			}

			console.log("CardDetails.stripeFormSubmit.meta", meta);

			// Initial post to Stripe. This returns a paymentMethod.id token that is passed to our backend.
			this.stripe.createPaymentMethod({
				type: "card", 
				card: this.cardNumberEl, 
				billing_details: meta
			}).then(result => {
				// Stripe inputs error handling
				if (result.error || !isValid) {
					this.toggleStripeError(result)
					return false;
				}

				// Save payment method
				return $.ajax({
					url: '/finance/card-details/new-card',
					type: 'POST',
					data: $.param({ paymentMethodId: result.paymentMethod.id })
				}).then(function(response) {
					var cardResponse = JSON.parse(response);
					if (!cardResponse.success) {
						this.showCardError("There was a problem with this card - code: "+cardResponse.declineCode);
						return;
					}
					this.showCardError(null);
					this.interface.fetch(true);
				}.bind(this))
			}).
			then(() => {
				this.ui.button.enable();
			});
		} catch (err) { 
			this.ui.button.enable();
		}
		return false;
	}

	toggleStripeError(event) {
		if(event.error) {
			this.showCardError(event.error);
		} else {
			this.showCardError(null);
		}
	}

	showCardError(errorMessage) {
		var container = $("#stripe-error-container");
		if (errorMessage) {
			container.find(".parsley-required").text(errorMessage.message || errorMessage);
			container.collapse('show');
		} else {
			container.collapse('hide');	
		}
	}

	setupPreferencesForm() {
		console.log("setupPreferencesForm() invoked");
		this.setFrequencyType($("input[type=radio][name=frequencyType]:checked").val())

		$('input[type=radio][name=frequencyType]').change(function() {
			console.log("change()");
			this.setFrequencyType($(event.target).val());
		}.bind(this));
		
		new Form({
			callback: this.postPreferencesForm.bind(this),
			form: this.ui.preferencesForm
		});
	}

	postPreferencesForm() {
		console.log("CardDetails.postPreferencesForm()")
		this.interface.fetch(true);
	}

	setFrequencyType(frequencyType) {
		console.log("CardDetails.setFrequencyType()", frequencyType);

		let weekSelector = $("#js-day-of-week-selector");
		let monthSelector = $("#js-day-of-month-selector");

		if (frequencyType == "weekly") {
			weekSelector.collapse('show');
			monthSelector.collapse('hide');
			monthSelector.find("select").val("");
		} else if (frequencyType == "monthly") {
			monthSelector.collapse('show');
			weekSelector.collapse('hide');
			weekSelector.find("select").val("");
		} else {
			weekSelector.collapse('hide');
			monthSelector.collapse('hide');
		}
	}
}