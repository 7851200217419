// Contains code for Stripe payments that is shared between the donate tool and the 3d secure verification
// process for repeat donations. 
// @see donate.js and verify-recurring-payment.js for example integrations
// @author Matthew Norris

'use strict';

var StripeUtils = function(options) {

	var self = this;

	self.isRepeat = options.isRepeat ? true : false;
	self.onSuccess = options.onSuccess;
	self.onFail = options.onFail
	self.confirmEndpoint = options.confirmEndpoint ? options.confirmEndpoint : "/donate/confirm";

	// Setup Stripe's client object - owned by this class
	var apiKey = $(document.body).data('stripeApiKey');
	var stripe = Stripe(apiKey);
	this.stripe = stripe;

	// Confirm payment function, this follows the Stripe code example by calling itself
	this.verify = function(response) {
		console.log("StripeUtils.verify()", response);

		if(!response.success) {
			self.onFail(response);

		} else if (response.requiresAction) {
			console.log("StripeUtils.verify()", "3D secure needed", {"paymentIntentClientSecret":response.paymentIntentClientSecret})

			// 3D secure authorisation required. Call Stripe's handleCardAction method, this will show a confirm modal.
			self.stripe.handleCardAction(response.paymentIntentClientSecret).then(function(cardActionResult) {

				console.log("StripeUtils.verify()", "stripe.handleCardAction", cardActionResult);

				if (cardActionResult.error) { 
					self.onFail(response)

				} else {
					console.log("StripeUtils.verify()", "stripe.handleCardAction", "3D secure action passed");
					return $.ajax({
						url: self.confirmEndpoint,
						type: 'POST',
						data: {
							paymentIntentId: cardActionResult.paymentIntent.id,
							isRepeat: self.isRepeat
						}
					}).then(function(confirmResult) {
						console.log("StripeUtils.verify()", "stripe.handleCardAction", "Verification complete", confirmResult);

						// Self call to validate the response. It might end up showing the 3d secure form again
						self.verify(confirmResult)
					});
				}
			});

		} else {
			self.onSuccess(response);
		}
		return response;
	}
}

module.exports = StripeUtils;