import Fetcher        from 'Scripts/common/fetcher';
import LoadingButton  from "Scripts/common/loading-button";
import CharityChooser from "Scripts/common/charity-chooser";

export default class CharityList {
	constructor() {
		this.ui = {
			addBtn:    $(".js-open-charities-modal"),
			addModal:  $("#js-corporate-charity-modal"),
			infoModal: $("#js-modal-select-charity"),
		};

		this.list = new Fetcher({
			element: "#js-charity-list-fetcher",
			callback: this.bindListEvents.bind(this),
		});

		this.bindEvents();
		this.bindListEvents();
	}

	bindEvents() {
		console.log("CharityList.bindEvents")
		this.ui.addBtn.on('click', this.onClickModalTrigger.bind(this));
	}

	bindListEvents() {
		console.log("CharityList.bindListEvents")
		$(".js-update-charity").on('click', this.onClickUpdateCharity.bind(this));
		$(".js-remove-charity").on('click', this.onClickRemoveCharity.bind(this));
	}

	onClickModalTrigger(e) {
		console.log("CharityList.onClickModalTrigger");
		const el = $(e.currentTarget);
		const btn = new LoadingButton(el);
		btn.disable();

		this.addModalFetcher = new Fetcher({
			element:  "#js-corporate-charity-modal-fetcher",
			modal:    this.ui.addModal,
			callback: () => {
				this.onModalLoad();
				btn.enable();
			},
		});
	}

	onModalLoad() {
		console.log("CharityList.onModalLoad");

		// This is here rather than in bindEvents because it is removed by onClickAddCharity so this allows it to be re-added without recalling bindEvents
		this.ui.infoModal.on('hidden.bs.modal.hideInfo', this.onHideInfoModal.bind(this));

		this.charityChooser = new CharityChooser({
			handler: {
				'corporate-charity': this.onClickAddCharity.bind(this),
			},
		});
	}

	async onClickAddCharity(data) {
		console.log("CharityList.onClickAddCharity");
		this.ui.infoModal.off('.hideInfo'); // Hack - stops onHideInfoModal() being triggered when closing the modal

		const formData = new FormData();
		formData.append("charityId", data.cid);
		formData.append("featured", true);

		this.sendRequest('/manage/charities/add', formData);
	}

	onClickUpdateCharity(e) {
		console.log("CharityList.onClickUpdateCharity");

		const data = $(e.currentTarget).data();
		const formData = new FormData();
		formData.append("charityId", data.cid);
		formData.append("featured", data.featured);

		this.sendRequest('/manage/charities/update', formData);
	}

	onClickRemoveCharity(e) {
		console.log("CharityList.onClickRemoveCharity");

		const data = $(e.currentTarget).data();
		const formData = new FormData();
		formData.append("charityId", data.cid);

		this.sendRequest('/manage/charities/remove', formData);
	}

	async sendRequest(endpoint, formData) {
		const response = await fetch(endpoint, {
			method: 'POST',
			body: formData,
		});

		if(!response.ok) {
			console.error('Error:', error);
			return;
		}

		this.list.fetch();
		this.ui.addModal.modal('hide');
		this.ui.infoModal.modal('hide');
	}

	onHideInfoModal(e) {
		console.log("CharityList.onHideInfoModal");
		this.ui.addModal.modal('show');
	}
}