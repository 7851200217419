import AjaxFormErrorHandler   from 'Scripts/common/ajax-form-error-handler';
import ImageUploadCrop        from 'Scripts/common/image-upload-crop';
import ImageUploadGallery     from 'Scripts/common/image-upload-gallery';
import LoadingButton          from 'Scripts/common/loading-button';
import Slugify                from 'Vendor/slugify';
import TextAreaExpand         from 'Scripts/common/text-area-expand';
import UriAvailabilityChecker from 'Scripts/donate/uri-availability-checker';

export default class CampaignForm {
	constructor() {
		this.ui = {
			// Forms
			form:         $('#js-create-campaign-form'),
			advForm:      $('#js-adv-campaign-form'),
			// Inputs
			inputTarget:  $('#target'),
			inputTitle:   $('#title'),
			inputUri:     $('#uri'),
			inputCtas:    $('.js-cta'),
			inputTwitter: $('#shareTextTwitter'),
			// Toggles and buttons
			generateUri:  $('.js-generate-uri'),
			toggleTarget: $('.js-toggle-target'),
			handle:       $('.js-handle'),
		};

		// The banner
		this.image = new ImageUploadCrop({
			width:     1170,
			height:    350,
			restrict:  true,
		});

		// The banner gallery 
		this.gallery = new ImageUploadGallery({
			cropper: this.image,
			preview: $(".js-crop-result"),
			previewContainer: $(".js-crop-result-container"),
		});

		// Errors
		this.errorHandler = new AjaxFormErrorHandler({ useClasses: true });

		// Button
		this.ui.button = new LoadingButton(this.ui.form.find('.js-submit'));

		// URI checker
		new UriAvailabilityChecker('.js-uri-lookup', '/manage/campaigns/uri-availability');

		// This makes <textarea> inputs expand as you type
		new TextAreaExpand();
	
		this.bindEventHandlers();
	}

	bindEventHandlers() {
		this.ui.generateUri.on('click', this.generateUri.bind(this, true));
		this.ui.inputCtas.on('change', this.onChangeCtas.bind(this));
		this.ui.inputTitle.on('blur', this.generateUri.bind(this, false));
		this.ui.toggleTarget.on('change', this.onToggleTarget.bind(this));
		this.ui.handle.on('click', this.onClickHandle.bind(this));

		this.ui.form.on('submit', this.onSubmit.bind(this));
	}

	// Allow user to disable the fundraising target. Removes any existing value but remembers it so you can toggle
	onToggleTarget() {
		let disabled = this.ui.toggleTarget.is(":checked");
		let input = this.ui.inputTarget; 

		input.prop('disabled', disabled);

		if(disabled){
			input.data('value',input.val()).val('');
		} else {
			input.val(input.data('value') || '');
		}
	}

	// Generate a URI for the page, based on slug of title. Will auto-trigger validation to see if it exists already or not.
	generateUri(force, e) {
		let generatedUri = Slugify(this.ui.inputTitle.val(), {lower: true, remove: /[^A-Za-z0-9-_\s]/g}); // Spaces are "allowed" as Slugify auto-changes to dashes
		console.log("CampaignForm.generateUri()", generatedUri, force);

		let inputUri = this.ui.inputUri;
		let currentUri = inputUri.val();

		if(!$('.js-uri-lookup-result').hasClass('text-green') || force){
			inputUri.val(generatedUri).trigger('keyup');
		}
		else {
			this.ui.generateUri.collapse(currentUri.startsWith(generatedUri) ? 'hide' : 'show');
		}
	}

	// Don't allow them to set primary CTA == secondary CTA (todo: deal with in Java as well, just in case)
	onChangeCtas(e) {
		console.log("CampaignForm.onChangeCtas()");

		let changed  = $(e.currentTarget);
		let other    = this.ui.inputCtas.filter((i,e) => { return e != changed[0] });

		if(changed.val() == other.val()) {
			other.find("option").each((i,option) => {
				if($(option).val() != '' && $(option).val() != changed.val()) {
					other.val($(option).val());
				}
				return;
			})
		}
	}

	onClickHandle(e) {
		console.log("CampaignForm.onClickHandle()");
		let handle = $(e.currentTarget).text();
		this.ui.inputTwitter.append(' ' + handle);
	}

	// Before submitting, get the form data from the advanced modal, then add image. todo: is there a neater way to do this?
	getFormData() {
		console.log("CampaignForm.getFormData()");

		let formData     = new FormData(this.ui.form.get(0));
		let advFormData  = new FormData(this.ui.advForm.get(0));

		for (let pair of advFormData.entries()) {
			formData.append(pair[0], pair[1]);
		}

		if(this.gallery.lastChosenImage == "gallery"){
			return formData;
		}

		return this.image.addImagesToFormData(formData, "images");
	}

	onSubmit(e) {
		e.preventDefault();
		console.log('CampaignForm.submit()');
		
		let endpoint = this.ui.form.attr('action');
		let formData = this.getFormData();

		this.ui.button.disable();

		return $.ajax({
			url: endpoint,
			method: 'POST',
			data: formData,
			processData: false,
			contentType: false
		}).then(resp => {
			if(resp.success) {
				this.ui.button.success();
				window.location.href = '/manage/campaigns';
			} else {
				this.ui.button.enable();
				this.errorHandler.handleErrors(resp.payload.validation);
			}
		});
	}
}