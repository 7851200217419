// JS for corporate registration form
// @author Matthew Norris

import Form      from 'Scripts/common/form';
import Loqate    from 'Scripts/donate-form/loqate.js';
import TomSelect from 'Scripts/common/tom-select';

export default class CreateCorporate {
	constructor() {
		new Form({
			callback:     this.postForm.bind(this),
			form:         $("#js-manage-corporate-form"),
			willRedirect: true,
		});

		// Setup country dropdown
		let countyDropdown = document.getElementById("country");
		if(countyDropdown && countyDropdown.length){
			const tsCountry = new TomSelect(countyDropdown, {
				disableAutocomplete: true,
			});

			// Set up Loqate
			new Loqate({ input: $("#loqate"), countryInput: tsCountry, enterManuallyBtn: $("#js-manual-address") });
		}
	}

	postForm(data) {
		console.log(data);
		window.location.href = data.payload.redirectUrl;
	}
}
